.slide-appear {
  opacity: 0;
  transform: translateX(50%);
}
.slide-appear-active {
  opacity: 1;
  transform: translateX(0);
  /* transition: opacity ${animateDuration}ms, 
    transform ${animateDuration}ms; */
}
